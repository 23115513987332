import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { environment } from '../../environments/environment';
import { SettingsDataService } from '../services/crowdbuilding-cms/services/data/personal/settings.data.service';
import { take } from 'rxjs/operators';
import { map } from 'rxjs';

export const TranslationTitleResolver: ResolveFn<string> = (
  route: ActivatedRouteSnapshot
) => {
  const titleTranslationKey = route.data['titleTranslationKey'];
  const titleTranslationParams = route.data['titleTranslationParams'] || {};
  if (! titleTranslationKey) {
    return '';
  }

  const appName = inject(SettingsDataService).peekItem()?.name || environment.appName;

  return inject(TranslocoService).selectTranslate(titleTranslationKey, titleTranslationParams)
    .pipe(
      take(1),
      map(str => appName ? `${appName} - ${str}` : str)
    );
};
